import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  isLoading: false,
  isLoadingCommissionPay: false,
  commissionPay: [],
  dataPaging: {},
  dataPagingCommissionPay: {},
};

const slice = createSlice({
  name: 'commissions',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingCommissionPay(state) {
      state.isLoadingCommissionPay = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCommissions(state, action) {
      state.isLoading = false;
      state.commissions = action.payload;
    },

    getCommissionPay(state, action) {
      state.isLoadingCommissionPay = false;
      state.commissionPay = action.payload;
    },

    getDataPaging(state, action) {
      state.isLoading = false;
      state.dataPaging = action.payload;
    },

    getDataPagingCommissionPay(state, action) {
      state.isLoading = false;
      state.dataPagingCommissionPay = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCommissions(queryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('commissions', { params: queryParams });

      const { commissions } = response?.data?.data || [];

      let newCommissions = commissions;
      if (newCommissions?.per_page) {
        newCommissions = newCommissions.data;
      }

      const newData = newCommissions.map((item, key) => {
        const internalOrgNames = item?.commission_internal_orgs.map((item) => item.internal_org.name).join(', ');
        const internalOrgs = item?.commission_internal_orgs.map((item) => item.internal_org);
        return {
          ...item,
          internal_org_name: internalOrgNames,
          internal_orgs: internalOrgs,
          user_name: item?.user?.name,
          status: 'Đang hoạt động',
        };
      });

      dispatch(slice.actions.getCommissions(newData));
      dispatch(slice.actions.getDataPaging(commissions));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCommissionPay(queryParams) {
  return async () => {
    dispatch(slice.actions.startLoadingCommissionPay());
    try {
      const response = await axios.get('commissions/pay', { params: queryParams });

      const { commissions } = response?.data?.data || [];

      let newCommissions = commissions;
      if (newCommissions?.per_page) {
        newCommissions = newCommissions.data;
      }

      const newData = newCommissions.map((item, key) => {
        return {
          ...item,
          increment: key + (commissions?.from || 1),
          order_code: item?.order_item?.order?.code,
          good_code: item?.order_item?.good?.code,
          good_name: item?.order_item?.good?.name,
          price: item?.price,
          discount_price: item?.commission_amount,
          percent_discount: item?.percent_discount,
        };
      });

      dispatch(slice.actions.getCommissionPay(newData));
      dispatch(slice.actions.getDataPagingCommissionPay(commissions));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
