import PropTypes from 'prop-types';
import React, { useLayoutEffect, useState } from 'react';

// @mui
import { List, Stack } from '@mui/material';
// locales
import { useTheme } from '@mui/material/styles';
import { useLocales } from '../../../locales';
//
import { StyledSubheader } from './styles';
import NavList from './NavList';
import { useAuthContext } from '../../../auth/useAuthContext';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
};

export default function NavSectionVertical({ data, sx, ...other }) {
  const { user } = useAuthContext();

  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const { translate } = useLocales();

  const [userPermissions, setUserPermissions] = useState([]);

  const [dataNav, setDataNav] = useState([]);

  useLayoutEffect(() => {
    if (user) {
      const permissions = [];
      if (user?.roles) {
        user.roles.forEach((role) => {
          permissions.push(...role.permissions);
        });
      }
      const uniquePermissions = [...new Set(permissions)];

      setUserPermissions(uniquePermissions.filter((item) => item.name.endsWith('-list')));
      setDataNav(data);
    }
  }, [user]);

  const isAllowed = (type, data) => {
    if (data === undefined || userPermissions === undefined) {
      return false;
    }

    if (type === 'subheader') {
      return checkPermissionSubHeader(data.items);
    }
    if (type === 'subheader2') {
      return checkPermissionSubHeader(data);
    }

    if (type === 'listMenu') {
      return checkPermissionPage(data);
    }

    return false;
  };

  const checkPermissionSubHeader = (data) => {
    let result = false;
    const userPermissionsList = userPermissions.filter(
      (per) => (per?.name || '').toLowerCase().indexOf('-list') !== -1
    );
    data.some((item) => {
      if (item.screen_id && userPermissionsList.some((p) => p.screen_id === item.screen_id)) {
        result = true;
        return true; // Dừng vòng lặp
      }

      if (item.children) {
        result = checkPermissionSubHeader(item.children);
        if (result) return true; // Dừng vòng lặp
      }
      return false; // Tiếp tục vòng lặp
    });

    return result;
  };

  const checkPermissionPage = (data) => {
    let result = false;
    const userPermissionsList = userPermissions.filter(
      (per) => (per?.name || '').toLowerCase().indexOf('-list') !== -1
    );

    if (data.screen_id && userPermissionsList.some((p) => p.screen_id === data.screen_id)) {
      result = true;
      return true; // Dừng vòng lặp
    }

    if (data.children) {
      data.children.forEach((itemChild) => {
        result = checkPermissionPage(itemChild);
      });
      if (result) return true; // Dừng vòng lặp
    }

    return result;
  };

  return (
    <Stack sx={sx} {...other}>
      {dataNav.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {isAllowed('subheader', group) && group.subheader && (
              <StyledSubheader sx={{ color: PRIMARY_MAIN, fontSize: 12 }} disableSticky>
                {translate(group.subheader)}
              </StyledSubheader>
            )}

            {group.items.map((list) => {
              let type = 'listMenu';
              let data = list;
              if (list.children) {
                type = 'subheader2';
                data = list.children;
              }
              return (
                isAllowed(type, data) && (
                  <NavList
                    key={list.title + list.path}
                    data={list}
                    depth={1}
                    hasChild={!!list.children}
                    checkIsAllowed={isAllowed}
                    theme={theme}
                  />
                )
              );
            })}
          </List>
        );
      })}
    </Stack>
  );
}
