import { lazy, Suspense } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const ChangePasswordPage = Loadable(lazy(() => import('../pages/dashboard/ChangePasswordPage')));

// DASHBOARD: GENERAL
export const DashboardPage = Loadable(lazy(() => import('../pages/dashboard/DashboardPage')));
// export const GeneralEcommercePage = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommercePage')));
// export const GeneralAnalyticsPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalyticsPage')));
// export const GeneralBankingPage = Loadable(lazy(() => import('../pages/dashboard/GeneralBankingPage')));
// export const GeneralBookingPage = Loadable(lazy(() => import('../pages/dashboard/GeneralBookingPage')));
// export const GeneralFilePage = Loadable(lazy(() => import('../pages/dashboard/GeneralFilePage')));

// ORGANIZTION
export const InternalOrganizationListPage = Loadable(
  lazy(() => import('../pages/dashboard/InternalOrganizationListPage'))
);

// DASHBOARD: EMPLOYEE
export const EmployeeListPage = Loadable(lazy(() => import('../pages/dashboard/EmployeeListPage')));

// DASHBOARD: PARTIES
export const PartiesListPage = Loadable(lazy(() => import('../pages/dashboard/PartiesListPage')));

export const PartyTypesListPage = Loadable(lazy(() => import('../pages/dashboard/PartyTypesListPage')));

export const GroupSupermarketListPage = Loadable(lazy(() => import('../pages/dashboard/GroupSupermarketListPage')));

// DASHBOARD: GOODS
export const GoodListPage = Loadable(lazy(() => import('../pages/dashboard/GoodListPage')));

// DASHBOARD: GOOD Category
export const GoodCategoryListPage = Loadable(lazy(() => import('../pages/dashboard/GoodCategoryListPage')));

// DASBOARD: WORK DAILY
export const WorkDailyListPage = Loadable(lazy(() => import('../pages/dashboard/WorkDailyListPage')));

// CR
export const CrCategoryListPage = Loadable(lazy(() => import('../pages/dashboard/CrCategoryListPage')));
export const FileManagerPage = Loadable(lazy(() => import('../pages/dashboard/FileManagerPage')));

// Conclusion Contract
export const ConclusionContractPage = Loadable(lazy(() => import('../pages/dashboard/ConclusionContractListPage')));

// ORDER
export const ImportWarehouseListPage = Loadable(lazy(() => import('../pages/dashboard/ImportWarehouseListPage')));
export const OrderGiftPage = Loadable(lazy(() => import('../pages/dashboard/OrderGiftPage')));
export const OrderGiftDetail = Loadable(
  lazy(() => import('../sections/@dashboard/order-gift/OrderGiftDetail/OrderGiftDetail'))
);
export const WarehouseExportPage = Loadable(lazy(() => import('../pages/dashboard/WarehouseExportPage')));
export const SaleReceiptListPage = Loadable(lazy(() => import('../pages/dashboard/SalesReceiptListPage')));
export const SaleReceiptDetailPage = Loadable(lazy(() => import('../pages/dashboard/SalesReceiptDetailPage')));
export const SaleReceiptPaymentDetaiPage = Loadable(
  lazy(() => import('../pages/dashboard/SalesReceiptPaymentDetailPage'))
);
export const OrderSampleListPage = Loadable(lazy(() => import('../pages/dashboard/OrderSampleListPage')));
export const RefundOrderCreatePage = Loadable(lazy(() => import('../pages/dashboard/RefundOrderCreatePage')));
export const PrintOrder = Loadable(lazy(() => import('../sections/@dashboard/sales-receipt/OrderDetail/PrintPage')));
export const PrintOrderGift = Loadable(
  lazy(() => import('../sections/@dashboard/order-gift/OrderGiftDetail/PrintPage'))
);

// DASHBOARD: Phân quyền
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/UserListPage')));
export const UserPermistionListPage = Loadable(lazy(() => import('../pages/dashboard/UserPermistionListPage')));
export const RoleListPage = Loadable(lazy(() => import('../pages/dashboard/RoleListPage')));

// Histtory Care Customer
export const CustomerCarePage = Loadable(lazy(() => import('../pages/dashboard/CustomerCarePage')));

// DASHBOARD: Debts
export const DebtsListPage = Loadable(lazy(() => import('../pages/dashboard/DebtsListPage')));
export const DebtsDetail = Loadable(lazy(() => import('../sections/@dashboard/debts/detail/DebtsDetail')));

// DASHBOARD: RenvenueCustomerListPage
export const RenvenueCustomerListPage = Loadable(lazy(() => import('../pages/dashboard/RenvenueCustomerListPage')));

// DASHBOARD: RenvenueEmployeeListPage
export const RenvenueEmployeeListPage = Loadable(lazy(() => import('../pages/dashboard/RenvenueEmployeeListPage')));

// DASHBOARD: RenvenueEmployeeListPage
export const ImportInventoryListPage = Loadable(lazy(() => import('../pages/dashboard/ImportInventoryListPage')));

// Inventory
export const InventoryListPage = Loadable(lazy(() => import('../pages/dashboard/InventoryListPage')));

export const InventoryManagementListPage = Loadable(
  lazy(() => import('../pages/dashboard/InventoryManagementListPage'))
);

export const ProductReportListPage = Loadable(lazy(() => import('../pages/dashboard/ProductReportListPage')));

// PAYMENT
export const PaymentListPage = Loadable(lazy(() => import('../pages/dashboard/PaymentListPage')));
export const PaymentOrderCreatePage = Loadable(lazy(() => import('../pages/dashboard/PaymentOrderCreatePage')));
export const PaymentAccountListPage = Loadable(lazy(() => import('../pages/dashboard/PaymentAccountListPage')));

// DASHBOARD: APP

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(lazy(() => import('../pages/dashboard/PermissionDeniedPage')));

export const TagertMonthListPage = Loadable(lazy(() => import('../pages/dashboard/TagertMonthListPage')));

export const BlogListPage = Loadable(lazy(() => import('../pages/dashboard/BlogListPage')));
export const BlogViewDetailPage = Loadable(lazy(() => import('../pages/dashboard/BlogViewDetailPage')));

export const CommissionListPage = Loadable(lazy(() => import('../pages/dashboard/CommissionListPage')));

export const CommissionPayListPage = Loadable(lazy(() => import('../pages/dashboard/CommissionPayListPage')));

export const SupermarketListPage = Loadable(lazy(() => import('../pages/dashboard/SupermarketListPage')));

export const CostManagementListPage = Loadable(lazy(() => import('../pages/dashboard/CostManagementListPage')));

export const CostCategoryListPage = Loadable(lazy(() => import('../pages/dashboard/CostCategoryListPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const FaqsPage = Loadable(lazy(() => import('../pages/FaqsPage')));
export const AboutPage = Loadable(lazy(() => import('../pages/AboutPage')));
export const Contact = Loadable(lazy(() => import('../pages/ContactPage')));
export const PricingPage = Loadable(lazy(() => import('../pages/PricingPage')));
export const PaymentPage = Loadable(lazy(() => import('../pages/PaymentPage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

// DEMO COMPONENTS
// ----------------------------------------------------------------------

// export const ComponentsOverviewPage = Loadable(lazy(() => import('../pages/components/ComponentsOverviewPage')));

// FOUNDATION
// export const FoundationColorsPage = Loadable(lazy(() => import('../pages/components/foundation/FoundationColorsPage')));
// export const FoundationTypographyPage = Loadable(
//   lazy(() => import('../pages/components/foundation/FoundationTypographyPage'))
// );
// export const FoundationShadowsPage = Loadable(
//   lazy(() => import('../pages/components/foundation/FoundationShadowsPage'))
// );
// export const FoundationGridPage = Loadable(lazy(() => import('../pages/components/foundation/FoundationGridPage')));
// export const FoundationIconsPage = Loadable(lazy(() => import('../pages/components/foundation/FoundationIconsPage')));

// MUI COMPONENTS
// export const MUIAccordionPage = Loadable(lazy(() => import('../pages/components/mui/MUIAccordionPage')));
// export const MUIAlertPage = Loadable(lazy(() => import('../pages/components/mui/MUIAlertPage')));
// export const MUIAutocompletePage = Loadable(lazy(() => import('../pages/components/mui/MUIAutocompletePage')));
// export const MUIAvatarPage = Loadable(lazy(() => import('../pages/components/mui/MUIAvatarPage')));
// export const MUIBadgePage = Loadable(lazy(() => import('../pages/components/mui/MUIBadgePage')));
// export const MUIBreadcrumbsPage = Loadable(lazy(() => import('../pages/components/mui/MUIBreadcrumbsPage')));
// export const MUIButtonsPage = Loadable(lazy(() => import('../pages/components/mui/MUIButtonsPage')));
// export const MUICheckboxPage = Loadable(lazy(() => import('../pages/components/mui/MUICheckboxPage')));
// export const MUIChipPage = Loadable(lazy(() => import('../pages/components/mui/MUIChipPage')));
// export const MUIDataGridPage = Loadable(lazy(() => import('../pages/components/mui/MUIDataGridPage')));
// export const MUIDialogPage = Loadable(lazy(() => import('../pages/components/mui/MUIDialogPage')));
// export const MUIListPage = Loadable(lazy(() => import('../pages/components/mui/MUIListPage')));
// export const MUIMenuPage = Loadable(lazy(() => import('../pages/components/mui/MUIMenuPage')));
// export const MUIPaginationPage = Loadable(lazy(() => import('../pages/components/mui/MUIPaginationPage')));
// export const MUIPickersPage = Loadable(lazy(() => import('../pages/components/mui/MUIPickersPage')));
// export const MUIPopoverPage = Loadable(lazy(() => import('../pages/components/mui/MUIPopoverPage')));
// export const MUIProgressPage = Loadable(lazy(() => import('../pages/components/mui/MUIProgressPage')));
// export const MUIRadioButtonsPage = Loadable(lazy(() => import('../pages/components/mui/MUIRadioButtonsPage')));
// export const MUIRatingPage = Loadable(lazy(() => import('../pages/components/mui/MUIRatingPage')));
// export const MUISliderPage = Loadable(lazy(() => import('../pages/components/mui/MUISliderPage')));
// export const MUIStepperPage = Loadable(lazy(() => import('../pages/components/mui/MUIStepperPage')));
// export const MUISwitchPage = Loadable(lazy(() => import('../pages/components/mui/MUISwitchPage')));
// export const MUITablePage = Loadable(lazy(() => import('../pages/components/mui/MUITablePage')));
// export const MUITabsPage = Loadable(lazy(() => import('../pages/components/mui/MUITabsPage')));
// export const MUITextFieldPage = Loadable(lazy(() => import('../pages/components/mui/MUITextFieldPage')));
// export const MUITimelinePage = Loadable(lazy(() => import('../pages/components/mui/MUITimelinePage')));
// export const MUITooltipPage = Loadable(lazy(() => import('../pages/components/mui/MUITooltipPage')));
// export const MUITransferListPage = Loadable(lazy(() => import('../pages/components/mui/MUITransferListPage')));
// export const MUITreesViewPage = Loadable(lazy(() => import('../pages/components/mui/MUITreesViewPage')));

// EXTRA
// export const DemoAnimatePage = Loadable(lazy(() => import('../pages/components/extra/DemoAnimatePage')));
// export const DemoCarouselsPage = Loadable(lazy(() => import('../pages/components/extra/DemoCarouselsPage')));
// export const DemoChartsPage = Loadable(lazy(() => import('../pages/components/extra/DemoChartsPage')));
// export const DemoCopyToClipboardPage = Loadable(
//   lazy(() => import('../pages/components/extra/DemoCopyToClipboardPage'))
// );
// export const DemoEditorPage = Loadable(lazy(() => import('../pages/components/extra/DemoEditorPage')));
// export const DemoFormValidationPage = Loadable(lazy(() => import('../pages/components/extra/DemoFormValidationPage')));
// export const DemoImagePage = Loadable(lazy(() => import('../pages/components/extra/DemoImagePage')));
// export const DemoLabelPage = Loadable(lazy(() => import('../pages/components/extra/DemoLabelPage')));
// export const DemoLightboxPage = Loadable(lazy(() => import('../pages/components/extra/DemoLightboxPage')));
// export const DemoMapPage = Loadable(lazy(() => import('../pages/components/extra/DemoMapPage')));
// export const DemoMegaMenuPage = Loadable(lazy(() => import('../pages/components/extra/DemoMegaMenuPage')));
// export const DemoMultiLanguagePage = Loadable(lazy(() => import('../pages/components/extra/DemoMultiLanguagePage')));
// export const DemoNavigationBarPage = Loadable(lazy(() => import('../pages/components/extra/DemoNavigationBarPage')));
// export const DemoOrganizationalChartPage = Loadable(
//   lazy(() => import('../pages/components/extra/DemoOrganizationalChartPage'))
// );
// export const DemoScrollbarPage = Loadable(lazy(() => import('../pages/components/extra/DemoScrollbarPage')));
// export const DemoSnackbarPage = Loadable(lazy(() => import('../pages/components/extra/DemoSnackbarPage')));
// export const DemoTextMaxLinePage = Loadable(lazy(() => import('../pages/components/extra/DemoTextMaxLinePage')));
// export const DemoUploadPage = Loadable(lazy(() => import('../pages/components/extra/DemoUploadPage')));
