import OneSignal from 'react-onesignal';
import axios from '../utils/axios';
import { ONESIGNAL_APP_ID } from '../config';

const pushSubscriptionChangeListener = (event) => {
  if (event.current.token) {
    window.localStorage.setItem('onesignal_id', event.current.id);
    axios.post('/token-send-notification', { device_id: event.current.id });
  }
};

export const runOneSignal = async (setInitialized) => {
  if (ONESIGNAL_APP_ID) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/OneSignalSDKWorker.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    }

    await OneSignal.init({ appId: ONESIGNAL_APP_ID, allowLocalhostAsSecureOrigin: true });
    OneSignal.Slidedown.promptPush();
    OneSignal.User.PushSubscription.addEventListener('change', pushSubscriptionChangeListener);
    setInitialized(false);
  }
};
