import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 80,
        height: 80,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >

      {/* <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="100%" height="100%" viewBox="0 0 1000.000000 1001.000000"
        preserveAspectRatio="xMidYMid meet">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,1001.000000) scale(0.100000,-0.100000)"
          fill="#9e1c21" stroke="none">
          <path d="M4785 9874 c-22 -2 -103 -9 -180 -15 -822 -64 -1661 -365 -2359 -847
-1095 -756 -1835 -1918 -2060 -3232 -87 -504 -87 -1148 -1 -1648 138 -802 460
-1540 950 -2177 771 -1002 1880 -1663 3125 -1864 270 -43 441 -56 775 -56 333
0 500 12 775 56 1350 212 2563 995 3326 2144 213 321 400 695 536 1070 99 274
188 635 232 940 61 422 62 996 1 1415 -221 1522 -1143 2857 -2491 3606 -580
322 -1211 519 -1884 589 -144 15 -653 28 -745 19z m695 -214 c1331 -129 2537
-807 3335 -1875 535 -717 850 -1552 925 -2450 13 -157 13 -595 0 -766 -18
-231 -72 -562 -126 -769 -112 -428 -273 -828 -482 -1195 -67 -118 -223 -355
-316 -480 -794 -1066 -2032 -1760 -3346 -1874 -292 -25 -282 -26 -311 6 -15
15 -35 44 -45 63 -18 33 -19 80 -22 986 -2 898 -1 952 15 973 10 12 59 37 111
55 123 45 193 88 272 167 105 106 177 244 219 419 39 164 46 238 45 490 -1
248 -11 378 -54 685 -32 231 -32 230 -58 233 -45 7 -50 -9 -36 -110 85 -602
97 -946 44 -1208 -34 -166 -35 -169 -116 -195 -38 -12 -111 -41 -163 -64 -105
-45 -150 -50 -240 -26 -111 29 -148 29 -255 -4 -54 -17 -114 -31 -133 -31 -20
0 -66 16 -111 39 -42 22 -98 45 -123 51 l-46 12 -27 86 c-49 159 -60 254 -60
527 0 267 7 361 55 688 32 219 31 237 -12 237 -28 0 -35 -26 -63 -225 -37
-263 -45 -346 -56 -540 -28 -506 62 -866 266 -1067 74 -73 132 -110 233 -149
111 -43 136 -57 154 -89 16 -26 17 -103 17 -921 0 -601 -4 -913 -11 -955 -6
-37 -23 -82 -40 -109 l-30 -47 -87 7 c-321 23 -512 48 -753 99 -660 139 -1285
423 -1841 838 -68 50 -150 114 -183 142 -33 28 -65 56 -72 61 -6 6 -65 60
-130 120 -773 719 -1276 1659 -1447 2705 -42 253 -51 388 -51 755 0 377 9 503
56 780 142 846 497 1612 1055 2277 95 112 397 419 509 514 218 188 429 341
657 477 619 370 1335 605 2003 657 77 6 160 12 185 14 80 7 582 -3 690 -14z"/>
          <path d="M1102 6778 l3 -42 74 -12 c175 -29 322 -150 367 -300 11 -38 14 -283
14 -1431 l1 -1385 -32 -69 c-61 -132 -204 -230 -371 -254 l-48 -7 0 -44 0 -44
680 0 680 0 0 45 c0 43 -2 45 -28 45 -42 0 -141 28 -198 55 -77 38 -159 121
-197 202 l-32 68 0 1405 0 1405 29 62 c38 84 122 163 223 211 l78 37 355 0
c314 0 363 -2 419 -18 151 -43 276 -121 394 -246 161 -171 228 -336 230 -566
0 -130 -12 -194 -62 -319 -77 -191 -241 -362 -434 -451 -133 -61 -171 -67
-489 -72 l-288 -5 0 -43 0 -43 243 -5 c266 -5 294 -10 425 -72 233 -110 423
-346 472 -586 17 -81 20 -137 20 -394 0 -395 20 -537 106 -759 91 -235 265
-456 479 -608 61 -44 155 -98 169 -98 3 0 -12 28 -33 62 -87 145 -145 328
-177 563 -12 91 -15 180 -13 370 2 138 3 383 4 545 0 277 -1 300 -23 379 -57
210 -176 364 -366 475 -101 60 -250 119 -359 143 -40 8 -76 19 -80 23 -4 4 20
13 55 20 169 34 379 119 508 205 95 63 209 172 264 252 159 234 161 580 5 819
-172 264 -568 465 -1011 513 -62 7 -451 11 -1078 11 l-981 0 3 -42z"/>
          <path d="M5350 6775 c0 -44 1 -45 33 -45 139 0 314 -107 378 -232 49 -95 51
-115 59 -633 8 -458 26 -1377 34 -1705 2 -80 11 -221 21 -315 22 -210 25 -625
6 -770 -31 -241 -91 -431 -183 -583 -11 -17 -17 -32 -13 -32 3 0 45 26 93 58
104 69 249 205 305 286 98 145 156 325 178 556 14 146 5 592 -41 2135 -5 182
-10 336 -10 344 0 23 21 0 233 -254 111 -132 211 -251 223 -265 12 -14 98
-115 191 -226 92 -110 226 -270 298 -355 71 -85 154 -183 184 -219 30 -36 73
-87 97 -115 23 -27 188 -223 366 -435 177 -212 339 -404 358 -427 19 -22 109
-130 200 -238 l165 -197 5 1659 5 1658 29 58 c33 69 113 152 183 189 56 31
151 58 200 58 32 0 33 1 33 45 l0 45 -498 0 -497 0 3 -44 4 -43 54 -6 c30 -3
87 -18 126 -33 96 -36 203 -139 239 -229 l24 -60 3 -1245 c2 -1053 0 -1242
-11 -1230 -8 8 -50 58 -93 110 -149 178 -197 235 -240 285 -55 65 -346 411
-589 700 -106 127 -221 264 -256 305 -66 79 -196 233 -376 448 -59 70 -152
181 -208 246 -132 156 -418 497 -558 664 l-111 132 -323 0 -323 0 0 -45z"/>
        </g>
      </svg> */}


      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="100%" height="100%" viewBox="0 0 500.000000 499.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,499.000000) scale(0.100000,-0.100000)"
          fill="#cc161f" stroke="none">
          <path d="M2170 4954 c-965 -131 -1763 -812 -2043 -1744 -387 -1284 319 -2646
1591 -3069 255 -85 487 -121 781 -121 400 0 717 71 1066 239 257 124 447 256
652 455 215 207 366 413 497 677 433 869 319 1908 -291 2664 -104 129 -300
319 -428 416 -351 263 -752 427 -1189 484 -141 19 -493 18 -636 -1z m675 -179
c236 -37 453 -106 665 -211 469 -232 821 -584 1051 -1049 111 -225 177 -433
215 -675 26 -162 26 -528 0 -690 -90 -572 -391 -1095 -836 -1452 -269 -215
-612 -381 -936 -452 -194 -42 -279 -51 -509 -51 -291 1 -465 28 -720 111 -587
194 -1073 619 -1345 1179 -101 208 -161 392 -207 635 -25 138 -25 612 0 750
46 243 107 430 206 635 348 714 1044 1202 1833 1285 120 13 465 4 583 -15z"/>
          <path d="M2305 4634 c-529 -58 -978 -273 -1341 -645 -426 -438 -639 -1013
-603 -1627 27 -442 185 -853 466 -1209 82 -105 276 -293 386 -375 287 -213
688 -380 954 -395 112 -7 167 6 221 53 73 62 72 55 72 491 0 213 -4 393 -8
400 -4 6 -33 21 -64 33 -106 39 -181 137 -214 277 -21 90 -21 310 0 468 24
176 24 175 51 175 13 0 26 -4 29 -9 3 -4 -3 -69 -14 -142 -24 -162 -28 -429
-7 -429 7 0 34 -13 60 -30 26 -16 55 -30 64 -30 8 0 43 12 76 27 65 29 56 30
175 -18 30 -13 36 -12 70 9 20 13 49 28 64 34 15 7 29 21 32 32 11 42 6 213
-11 341 -9 71 -17 148 -17 170 -1 36 2 40 25 43 25 3 26 0 37 -75 38 -250 40
-483 7 -593 -41 -132 -93 -194 -208 -250 l-72 -35 -3 -373 c-1 -229 2 -388 8
-411 16 -59 51 -103 105 -130 44 -23 61 -26 160 -26 88 0 130 6 211 28 371 99
692 276 957 527 285 269 498 631 598 1014 53 205 63 291 63 541 0 250 -10 336
-63 541 -213 816 -880 1428 -1719 1575 -114 20 -445 34 -547 23z m-555 -1253
c164 -50 290 -143 346 -258 25 -50 29 -70 29 -143 0 -73 -4 -93 -28 -141 -52
-107 -199 -212 -351 -250 l-50 -12 60 -17 c182 -51 291 -162 313 -322 4 -35 6
-155 3 -268 -6 -283 17 -429 84 -532 13 -21 21 -38 16 -38 -21 0 -113 69 -172
129 -44 44 -77 91 -102 141 -58 119 -70 180 -77 405 -6 171 -10 207 -27 245
-60 132 -167 188 -374 199 l-125 6 -3 43 -3 42 88 0 c195 1 310 34 388 114 81
82 100 131 100 256 0 100 -1 108 -34 168 -41 75 -103 135 -179 170 -53 25 -68
27 -227 30 -185 4 -225 -3 -275 -44 -63 -53 -60 -22 -60 -726 0 -621 1 -644
20 -675 26 -43 86 -82 141 -91 38 -6 44 -11 47 -35 l3 -27 -315 0 -316 0 0 29
c0 26 3 29 48 35 59 8 106 36 132 78 19 32 20 52 20 688 0 625 -1 656 -19 686
-25 41 -80 74 -139 82 -42 6 -47 10 -50 35 l-3 28 508 -3 c495 -4 510 -4 583
-27z m1781 -601 l524 -631 3 518 c1 285 0 533 -3 551 -7 38 -58 99 -94 114
-14 6 -42 14 -61 17 -29 5 -36 11 -38 34 l-3 27 230 0 231 0 0 -29 c0 -25 -4
-29 -37 -35 -51 -8 -99 -39 -123 -78 -19 -32 -20 -52 -20 -800 0 -423 -3 -768
-6 -768 -4 0 -12 7 -18 15 -31 42 -1002 1194 -1006 1194 -3 1 -5 -245 -5 -547
-1 -533 -1 -549 -23 -626 -25 -91 -58 -151 -116 -214 -39 -42 -136 -115 -143
-108 -2 2 5 19 16 37 67 116 96 342 71 573 -5 49 -14 328 -20 620 -6 292 -15
548 -21 568 -19 71 -101 137 -171 138 -24 0 -28 4 -28 30 l0 30 168 0 168 0
525 -630z"/>
        </g>
      </svg>

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/dashboard/app" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
