import PropTypes from 'prop-types';
// @mui
import { Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;
  return (
    <StyledRoot>
      {/* <Logo
        sx={{
          zIndex: 9,
          position: 'absolute',
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      /> */}

      <StyledSection>
        <Typography variant="h3" sx={{ mt: 10, mb: 0, maxWidth: 480, textAlign: 'center' }}>
          {/* <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="300px" viewBox="0 0 3386.000000 2367.000000"
            preserveAspectRatio="xMidYMid meet">
            <defs>
              <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
                <stop offset="0%" stopColor={PRIMARY_DARK} />
                <stop offset="100%" stopColor={PRIMARY_MAIN} />
              </linearGradient>

              <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
                <stop offset="0%" stopColor={PRIMARY_LIGHT} />
                <stop offset="100%" stopColor={PRIMARY_MAIN} />
              </linearGradient>

              <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
                <stop offset="0%" stopColor={PRIMARY_LIGHT} />
                <stop offset="100%" stopColor={PRIMARY_MAIN} />
              </linearGradient>
            </defs>
            <g transform="translate(0.000000,2367.000000) scale(0.100000,-0.100000)"
              fill="#9e1c21" stroke="none">
              <path d="M15290 23664 c-472 -26 -810 -66 -1185 -140 -1848 -364 -3504 -1405
-4640 -2919 -1259 -1676 -1772 -3784 -1429 -5865 437 -2653 2246 -4887 4758
-5877 665 -262 1355 -425 2111 -499 228 -22 900 -31 1147 -15 1488 96 2845
583 4045 1453 509 369 1032 867 1440 1370 422 522 778 1099 1047 1701 1001
2236 868 4829 -357 6957 -448 779 -1055 1497 -1763 2086 -170 141 -440 342
-634 472 -1106 736 -2343 1159 -3695 1262 -142 11 -727 21 -845 14z m561 -314
c1275 -52 2427 -392 3511 -1037 537 -319 1012 -694 1473 -1164 608 -619 1091
-1322 1444 -2101 365 -805 568 -1607 642 -2538 19 -233 16 -831 -5 -1080 -99
-1170 -439 -2236 -1023 -3209 -984 -1638 -2547 -2820 -4391 -3321 -486 -132
-996 -211 -1573 -245 l-107 -6 -37 38 c-50 51 -92 142 -105 228 -14 99 -14
2844 1 2882 21 55 58 82 161 114 214 66 403 190 528 345 332 414 420 1073 290
2154 -33 273 -83 590 -95 607 -16 21 -71 21 -92 0 -16 -16 -14 -38 25 -314 79
-558 96 -753 96 -1108 1 -356 -20 -543 -85 -782 l-31 -112 -49 -10 c-67 -15
-220 -73 -354 -134 -98 -45 -117 -51 -175 -50 -36 0 -117 13 -180 29 -167 41
-235 39 -375 -11 -95 -34 -122 -39 -195 -40 -91 0 -77 -5 -256 89 -40 20 -99
42 -132 48 l-61 11 -35 106 c-79 240 -109 475 -109 851 0 361 37 748 122 1276
12 75 15 125 10 136 -13 23 -77 35 -94 17 -21 -22 -83 -429 -126 -829 -29
-273 -37 -796 -15 -1002 74 -700 340 -1107 830 -1268 102 -34 154 -69 182
-121 12 -24 14 -228 14 -1451 0 -1547 1 -1503 -56 -1608 -51 -94 -46 -92 -245
-80 -753 46 -1442 188 -2119 437 -1601 589 -2951 1720 -3814 3193 -117 200
-329 620 -415 825 -326 770 -511 1549 -572 2405 -15 213 -15 759 0 965 70 955
289 1805 678 2640 614 1316 1631 2439 2890 3193 1046 626 2245 984 3461 1031
286 11 304 11 563 1z"/>
              <path d="M9470 18841 l0 -70 48 -6 c206 -27 349 -91 476 -213 101 -98 157
-206 175 -340 6 -38 11 -970 12 -2162 2 -1678 0 -2105 -10 -2145 -8 -27 -29
-84 -49 -125 -45 -94 -165 -219 -262 -273 -88 -49 -231 -95 -319 -103 l-72 -7
3 -71 3 -71 1063 -3 1063 -2 -3 72 -3 73 -70 6 c-249 23 -481 173 -581 375
-63 128 -58 -53 -61 2297 -3 1937 -2 2143 13 2200 50 193 213 364 424 444 134
50 208 56 688 50 458 -5 506 -10 667 -62 106 -35 229 -96 315 -157 98 -68 269
-236 346 -338 236 -312 306 -750 184 -1144 -60 -191 -172 -370 -334 -531 -196
-194 -385 -298 -648 -357 -84 -19 -135 -21 -521 -25 l-428 -5 3 -66 3 -67 350
-6 c374 -6 408 -9 573 -65 345 -116 688 -459 814 -814 58 -165 60 -185 68
-765 6 -422 11 -562 23 -640 87 -532 272 -911 612 -1250 162 -162 330 -287
503 -374 l60 -30 -45 67 c-156 233 -263 571 -309 977 -22 195 -23 577 -5 1135
19 546 8 693 -65 915 -145 440 -528 736 -1137 880 -64 15 -116 29 -115 31 2 1
57 14 123 29 560 124 1028 419 1233 778 55 95 108 243 127 354 19 109 19 312
0 428 -56 345 -261 632 -605 845 -261 163 -591 290 -890 344 -279 51 -228 49
-1887 53 l-1553 4 0 -70z"/>
              <path d="M16080 18841 l0 -70 58 -6 c106 -11 196 -38 294 -87 157 -78 258
-187 314 -334 53 -140 53 -151 64 -826 8 -519 31 -1723 54 -2783 2 -110 13
-285 25 -390 49 -458 54 -989 11 -1305 -47 -352 -137 -638 -269 -854 -23 -38
-40 -70 -38 -72 5 -5 174 102 254 162 109 81 320 294 380 384 102 152 171 314
217 506 80 336 81 517 22 2618 -25 885 -44 1611 -42 1613 5 6 -7 21 1806
-2137 932 -1108 1720 -2046 1752 -2084 l59 -69 -3 2579 -3 2579 24 60 c98 246
326 409 614 440 l57 6 0 70 0 69 -776 0 -776 0 7 -67 c4 -38 8 -69 9 -70 0 -1
39 -7 85 -13 278 -38 493 -203 578 -445 l28 -80 5 -1920 c4 -1056 5 -1922 3
-1925 -2 -2 -14 8 -26 23 -12 15 -868 1033 -1902 2262 l-1880 2234 -502 1
-503 0 0 -69z"/>
              <path d="M32840 6109 c-253 -42 -495 -219 -614 -449 -75 -145 -91 -212 -91
-380 0 -169 13 -229 79 -365 122 -254 347 -420 639 -471 394 -69 805 160 947
528 193 500 -123 1042 -664 1137 -70 13 -218 12 -296 0z m335 -144 c33 -8 103
-36 155 -62 79 -39 109 -61 180 -132 176 -177 245 -406 195 -646 -19 -92 -91
-236 -152 -304 -48 -54 -49 -54 -84 -42 -71 23 -120 73 -270 274 -82 110 -146
203 -143 208 3 5 13 9 22 9 31 0 137 56 171 91 49 48 71 103 71 177 0 77 -16
117 -69 175 -81 88 -168 107 -499 107 -144 0 -221 3 -217 10 3 5 37 28 75 51
76 45 179 84 261 98 71 13 228 6 304 -14z m-614 -190 c16 -9 34 -27 39 -41 14
-37 13 -872 -1 -913 -12 -34 -53 -61 -92 -61 -31 0 -112 96 -162 190 -131 251
-98 553 86 778 56 67 76 75 130 47z m430 -30 c88 -31 149 -122 149 -220 0 -59
-45 -143 -95 -177 -49 -34 -140 -58 -217 -58 l-58 0 0 229 0 230 28 4 c64 10
153 6 193 -8z m84 -760 l188 -255 96 0 96 -1 -30 -25 c-44 -36 -180 -101 -255
-121 -88 -24 -271 -23 -360 1 -74 20 -210 85 -255 121 l-30 25 203 0 c173 0
202 2 202 15 0 11 -12 15 -49 15 -38 0 -56 6 -78 24 l-28 24 -3 216 -3 216 58
0 59 0 189 -255z"/>
              <path d="M0 5681 l0 -49 43 -7 c95 -14 146 -29 227 -67 107 -52 187 -128 233
-225 l32 -68 6 -560 c4 -308 7 -1028 8 -1600 1 -1149 3 -1104 -62 -1214 -81
-134 -242 -227 -447 -256 l-35 -5 3 -50 4 -50 804 0 804 0 0 49 0 50 -47 6
c-154 21 -256 68 -354 165 -77 75 -114 141 -129 231 -7 45 -10 540 -8 1649 l3
1585 32 66 c59 119 178 219 318 267 l80 27 375 0 c325 0 386 -2 460 -18 180
-39 325 -117 467 -250 110 -104 173 -189 228 -302 160 -330 106 -723 -138
-1015 -124 -148 -315 -273 -487 -319 -121 -33 -228 -41 -522 -41 -310 0 -287
6 -272 -69 l6 -31 217 0 c238 0 362 -14 476 -53 248 -85 477 -288 585 -519 79
-170 82 -190 90 -658 8 -431 13 -496 60 -672 118 -448 407 -806 817 -1012 228
-114 459 -171 741 -184 l142 -7 0 51 0 51 -107 6 c-529 31 -857 377 -968 1022
-30 177 -37 286 -44 730 -8 410 -10 457 -30 533 -50 197 -171 379 -331 498
-123 91 -299 169 -500 220 -74 19 -136 36 -137 38 -2 2 34 11 79 21 135 28
353 104 465 161 311 158 504 375 570 642 25 102 22 322 -6 427 -75 281 -258
476 -606 645 -146 71 -284 120 -437 154 -244 55 -274 56 -1540 56 l-1168 0 0
-49z"/>
              <path d="M27300 5655 l0 -55 33 0 c57 0 185 -38 258 -76 132 -70 209 -167 247
-312 19 -71 21 -127 32 -717 6 -352 15 -820 21 -1040 16 -629 26 -1740 18
-1955 -9 -228 -21 -341 -55 -504 -30 -142 -72 -272 -124 -381 -21 -44 -37 -81
-36 -82 5 -5 190 116 251 165 250 201 378 418 435 737 40 221 42 370 15 1313
-13 483 -29 1046 -35 1252 -5 206 -12 417 -15 468 -2 52 -1 92 4 90 5 -2 633
-727 1396 -1613 763 -885 1395 -1619 1405 -1629 18 -18 19 41 17 1945 l-2
1965 38 76 c48 98 123 173 226 227 81 42 179 71 244 71 l37 0 0 55 0 55 -605
0 -605 0 0 -55 0 -55 38 0 c113 -1 290 -78 378 -166 59 -60 109 -154 124 -234
7 -36 9 -562 8 -1492 l-3 -1438 -1480 1720 -1480 1719 -392 0 -393 1 0 -55z"/>
              <path d="M23870 4994 c-54 -10 -148 -48 -197 -81 -154 -102 -303 -337 -361
-569 -14 -57 -29 -104 -33 -104 -5 0 -37 18 -71 41 -385 250 -991 308 -1484
144 -225 -75 -414 -193 -584 -365 -132 -133 -204 -240 -258 -383 -47 -126 -62
-201 -69 -347 -10 -219 23 -383 113 -565 141 -286 448 -539 809 -668 156 -55
413 -97 601 -97 423 0 874 -343 960 -728 25 -111 15 -316 -21 -437 -84 -285
-282 -484 -581 -584 -414 -139 -927 -37 -1221 242 -238 226 -302 525 -187 870
14 43 24 80 21 83 -9 10 -214 -27 -288 -51 -214 -72 -351 -221 -381 -417 -34
-227 112 -499 357 -665 221 -149 637 -270 1045 -304 706 -58 1419 208 1760
657 66 88 144 249 164 341 50 226 -16 441 -190 618 -190 193 -517 342 -929
425 l-59 12 104 34 c127 42 287 117 383 181 89 58 261 224 331 318 68 91 98
143 136 238 119 298 113 628 -15 903 -58 125 -113 202 -224 317 l-94 97 17 87
c28 142 88 242 173 290 l40 23 29 -41 c58 -84 153 -129 273 -129 97 0 157 20
216 72 55 49 83 100 96 176 24 147 -48 287 -176 343 -42 19 -158 31 -205 23z
m-1420 -590 c156 -36 315 -154 421 -312 66 -99 160 -294 194 -402 61 -196 89
-505 66 -709 -53 -451 -277 -776 -591 -858 -44 -11 -103 -17 -185 -16 -102 0
-132 4 -200 27 -109 35 -172 74 -261 158 -163 155 -292 412 -354 703 -28 130
-38 438 -20 582 34 277 147 523 308 674 125 118 256 168 447 168 65 1 137 -6
175 -15z"/>
              <path d="M4618 4442 l-528 -75 0 -54 0 -53 59 0 c32 0 96 -7 142 -16 157 -30
258 -95 310 -200 l24 -49 6 -690 c6 -744 8 -762 64 -972 47 -173 118 -312 233
-453 124 -154 272 -260 453 -324 170 -61 239 -71 474 -70 195 0 217 2 310 27
256 69 493 239 655 469 26 37 50 64 54 61 3 -3 6 -120 6 -260 l0 -253 535 0
535 0 0 48 0 48 -92 17 c-182 35 -335 142 -404 282 l-29 60 -7 1263 c-3 695
-8 1265 -10 1266 -2 2 -243 -31 -535 -72 l-533 -75 0 -54 0 -53 59 0 c75 0
189 -22 266 -51 81 -30 164 -113 191 -189 18 -52 19 -88 18 -595 -1 -669 -13
-782 -109 -1073 -140 -420 -376 -691 -661 -758 -96 -23 -319 -14 -424 16 -115
33 -205 85 -285 165 -82 82 -127 154 -166 267 -64 188 -62 143 -66 1366 -2
611 -7 1111 -11 1111 -4 -1 -244 -35 -534 -77z"/>
              <path d="M9175 4509 c-326 -44 -623 -195 -871 -443 -269 -270 -400 -562 -435
-973 -28 -331 58 -662 245 -943 317 -474 813 -712 1393 -669 270 20 508 110
733 278 108 81 319 293 377 381 112 168 195 380 234 596 29 158 31 417 5 549
-41 210 -133 422 -264 605 -83 118 -271 308 -374 378 -158 108 -366 191 -568
228 -102 19 -378 26 -475 13z m369 -104 c191 -50 359 -206 460 -427 68 -148
117 -335 153 -573 22 -151 25 -632 5 -795 -42 -331 -114 -566 -226 -735 -133
-199 -315 -298 -551 -298 -128 0 -199 15 -305 65 -71 34 -100 55 -175 132 -73
74 -100 110 -143 192 -87 168 -154 408 -182 654 -18 160 -24 473 -11 640 38
497 143 806 337 991 138 131 286 183 490 173 50 -2 117 -11 148 -19z"/>
              <path d="M11468 4442 l-528 -75 0 -52 0 -52 98 -6 c215 -14 348 -81 409 -205
l28 -57 6 -675 c6 -727 9 -772 64 -975 63 -232 147 -381 311 -548 168 -171
382 -272 652 -308 141 -19 386 -7 507 25 111 29 252 93 347 157 89 61 220 189
297 293 l66 88 3 -261 2 -261 541 0 c489 0 541 2 535 16 -3 9 -6 31 -6 49 0
33 -1 34 -47 40 -99 13 -207 53 -280 104 -84 59 -123 104 -166 191 l-32 65 -3
1263 -2 1264 -23 -5 c-12 -3 -255 -38 -539 -78 l-518 -73 0 -53 0 -53 59 0
c80 0 212 -26 277 -55 71 -31 146 -103 175 -168 l24 -52 0 -565 c0 -620 -3
-658 -65 -917 -110 -458 -353 -792 -650 -894 -96 -33 -290 -38 -423 -11 -310
64 -486 264 -554 632 -17 87 -18 183 -18 1193 1 751 -3 1097 -10 1096 -5 -1
-247 -35 -537 -77z"/>
              <path d="M17520 4443 c-289 -41 -526 -76 -526 -77 -1 0 -5 -25 -9 -53 l-7 -53
76 0 c224 0 390 -85 448 -228 l23 -57 3 -940 c2 -677 0 -957 -8 -1000 -40
-208 -232 -367 -482 -400 l-48 -6 0 -50 0 -49 805 0 805 0 0 48 0 48 -91 16
c-211 38 -384 178 -434 351 -15 53 -16 114 -12 605 5 578 10 660 59 867 48
208 144 435 250 594 66 98 195 225 278 274 116 67 182 82 365 82 137 0 172 -4
240 -23 233 -67 387 -226 458 -472 51 -175 52 -206 52 -1090 l0 -825 -22 -55
c-53 -129 -181 -241 -327 -284 -44 -14 -102 -28 -128 -31 l-48 -6 0 -50 0 -49
805 0 805 0 0 49 0 49 -52 7 c-204 25 -366 132 -446 293 l-37 76 -6 715 c-5
585 -9 730 -22 791 -35 173 -71 282 -133 404 -161 316 -438 516 -804 582 -135
25 -397 25 -510 1 -164 -35 -304 -95 -429 -184 -87 -62 -222 -200 -291 -296
l-55 -78 -3 290 c-1 160 -6 291 -10 290 -4 -1 -243 -35 -532 -76z"/>
              <path d="M25630 4514 c-237 -30 -447 -106 -637 -230 -374 -243 -605 -587 -684
-1019 -29 -155 -31 -392 -4 -540 72 -408 314 -776 666 -1011 240 -160 501
-235 824 -236 396 -1 673 107 994 387 311 271 463 588 503 1046 37 432 -110
822 -444 1175 -201 211 -472 355 -778 410 -82 15 -369 27 -440 18z m280 -98
c361 -66 570 -375 665 -986 22 -141 31 -568 16 -737 -42 -455 -147 -752 -330
-933 -130 -130 -263 -182 -461 -183 -199 0 -335 55 -470 192 -158 160 -250
377 -310 731 -81 476 -36 1092 104 1435 53 132 111 222 195 305 157 156 362
217 591 176z"/>
            </g>
          </svg>
 */}

          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="300px" viewBox="0 0 2048.000000 1448.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1448.000000) scale(0.100000,-0.100000)"
              fill="#cc161f" stroke="none">
              <path d="M9580 14114 c-157 -10 -319 -27 -435 -45 -1218 -183 -2298 -875
-2980 -1909 -731 -1108 -911 -2496 -490 -3764 276 -828 810 -1565 1520 -2098
695 -522 1530 -820 2402 -858 753 -32 1462 117 2123 446 617 308 1127 731
1546 1284 270 356 505 804 649 1236 74 222 145 525 174 739 6 44 16 116 22
160 18 136 27 472 18 671 -47 1082 -495 2097 -1265 2868 -710 710 -1615 1142
-2621 1251 -134 15 -552 27 -663 19z m570 -319 c853 -75 1640 -409 2290 -973
1155 -1001 1644 -2574 1260 -4057 -134 -517 -358 -983 -685 -1419 -211 -282
-513 -583 -810 -808 -766 -578 -1784 -868 -2745 -783 -271 24 -398 45 -645
106 -1018 254 -1889 889 -2453 1789 -331 528 -537 1153 -592 1795 -13 155 -13
518 0 665 63 698 284 1333 663 1900 698 1046 1841 1711 3082 1793 142 9 489 5
635 -8z"/>
              <path d="M9510 13524 c-238 -28 -392 -51 -525 -81 -1480 -325 -2625 -1517
-2889 -3008 -44 -250 -51 -335 -51 -655 0 -321 7 -417 51 -665 143 -807 568
-1577 1167 -2114 447 -401 938 -676 1492 -834 260 -75 357 -92 520 -92 141 0
154 2 214 27 113 49 178 110 217 206 l24 57 0 676 0 676 -23 21 c-13 12 -49
31 -81 42 -277 95 -415 343 -433 776 -6 154 6 351 32 534 8 52 19 136 26 185
7 50 16 98 21 108 11 21 56 23 83 3 19 -14 19 -17 -4 -172 -39 -266 -50 -401
-50 -579 0 -225 4 -243 50 -259 20 -7 65 -32 99 -54 81 -54 110 -54 219 -2
106 51 142 52 241 4 41 -20 94 -39 118 -42 39 -4 50 0 111 42 37 25 82 49 99
52 17 3 34 14 37 23 14 33 18 329 6 446 -17 167 -41 353 -51 400 -13 57 -12
129 2 143 14 14 74 16 82 3 12 -19 65 -436 76 -598 26 -390 -41 -694 -190
-856 -70 -77 -127 -115 -230 -153 -63 -24 -90 -40 -103 -61 -16 -26 -17 -72
-14 -693 2 -607 4 -669 20 -710 26 -66 82 -137 134 -171 88 -55 147 -72 280
-77 104 -4 139 -1 255 23 629 128 1216 417 1708 841 117 100 332 320 427 434
477 577 757 1231 849 1986 21 171 24 634 5 799 -75 667 -295 1256 -664 1780
-555 787 -1390 1333 -2332 1524 -236 48 -397 63 -700 66 -159 2 -306 1 -325
-1z m-1220 -2159 c277 -39 508 -144 666 -304 81 -81 126 -151 161 -253 34 -95
38 -262 9 -348 -43 -127 -105 -216 -214 -305 -85 -71 -277 -166 -402 -201 -52
-14 -102 -28 -110 -31 -8 -3 19 -13 60 -24 178 -45 334 -130 426 -232 65 -70
96 -124 131 -223 l28 -79 -3 -447 c-3 -483 3 -564 53 -753 23 -91 75 -212 115
-272 12 -18 19 -33 14 -33 -15 0 -120 64 -174 105 -30 24 -89 78 -131 121
-124 130 -193 245 -248 417 -49 153 -61 263 -61 549 0 279 -9 339 -66 454 -37
74 -133 171 -215 217 -125 70 -312 107 -539 107 l-110 0 0 75 0 75 158 0 c347
1 545 58 677 195 112 117 174 254 183 406 17 305 -165 567 -463 666 -76 26
-91 27 -333 31 -287 4 -346 -4 -443 -62 -58 -35 -111 -102 -129 -164 -6 -24
-10 -417 -10 -1129 0 -1078 0 -1093 21 -1147 35 -95 142 -172 275 -199 64 -13
69 -16 72 -41 2 -16 1 -39 -2 -52 l-6 -24 -543 2 -542 3 -3 51 -3 51 73 12
c131 21 219 78 262 171 21 45 21 52 24 1154 2 962 0 1117 -13 1161 -32 110
-157 202 -289 212 l-66 6 0 48 0 49 800 0 c665 0 818 -3 910 -15z m2790 -462
c217 -262 413 -497 435 -523 22 -25 258 -309 525 -630 l485 -583 3 929 c2
1045 7 983 -74 1073 -51 57 -114 89 -201 104 l-63 11 0 48 0 48 400 0 400 0 0
-49 0 -48 -40 -7 c-117 -19 -211 -81 -254 -168 l-26 -52 0 -1332 c0 -733 -3
-1335 -7 -1337 -5 -3 -69 67 -143 156 -74 88 -245 291 -380 451 -135 160 -308
365 -384 456 -76 91 -228 271 -336 400 -109 129 -263 312 -342 406 -78 93
-160 190 -180 214 l-38 44 0 -890 c0 -926 -4 -1036 -45 -1188 -41 -154 -123
-295 -232 -396 -66 -62 -212 -165 -221 -156 -2 3 7 25 21 49 63 106 103 240
132 432 19 129 19 451 0 610 -14 116 -21 350 -45 1512 -8 384 -14 524 -25 559
-37 124 -156 213 -307 231 l-48 6 0 48 0 49 298 0 297 -1 395 -476z"/>
              <path d="M320 4146 c14 -14 76 -51 196 -118 29 -15 57 -34 63 -42 8 -9 10
-358 9 -1267 l-3 -1254 -135 -78 -135 -77 485 0 485 0 -137 78 -138 77 0 435
0 435 51 -100 c90 -176 191 -318 324 -450 222 -223 472 -360 795 -438 112 -27
297 -55 395 -59 l60 -3 2 263 2 262 -212 0 c-240 0 -417 17 -581 56 -392 94
-657 282 -788 560 -21 45 -38 85 -38 88 0 3 155 6 344 6 264 0 362 3 423 15
288 55 501 210 609 442 40 84 59 154 74 265 24 171 -34 398 -139 552 -118 172
-302 292 -528 345 -88 20 -112 21 -794 21 -611 0 -701 -2 -689 -14z m1328
-119 c254 -155 396 -407 394 -702 -2 -287 -152 -546 -388 -669 l-51 -26 -294
2 -294 3 -3 705 c-1 388 0 711 3 718 4 10 63 12 287 10 l283 -3 63 -38z"/>
              <path d="M10351 4153 l-194 -3 187 -127 186 -126 0 -1152 0 -1152 -217 -144
-216 -144 242 -3 c133 -1 349 -1 479 0 l236 3 -144 106 c-80 58 -169 122 -197
143 l-53 38 0 1086 0 1086 48 -70 c26 -38 391 -584 811 -1214 420 -630 771
-1153 779 -1162 12 -14 31 -18 84 -18 l68 0 2 1283 3 1282 65 43 c36 24 134
86 218 138 83 53 152 100 152 105 0 5 -204 9 -485 9 -267 0 -485 -3 -485 -7 0
-5 90 -69 200 -143 121 -82 202 -143 205 -155 3 -11 4 -420 3 -910 l-3 -890
-234 350 c-129 193 -430 643 -670 1000 -239 358 -451 674 -470 703 l-34 52
-186 -2 c-102 -2 -273 -4 -380 -5z"/>
              <path d="M19560 4136 c-209 -59 -370 -268 -370 -481 1 -274 226 -498 502 -498
181 0 361 111 442 271 25 51 56 172 56 222 0 58 -32 185 -58 235 -65 120 -205
228 -330 254 -68 14 -185 13 -242 -3z m294 -82 c93 -37 175 -115 223 -210 31
-64 37 -88 41 -159 5 -94 -6 -153 -45 -232 -30 -62 -119 -150 -183 -183 -219
-112 -481 -25 -590 196 -29 60 -35 81 -38 160 -7 149 38 259 144 353 122 109
293 137 448 75z"/>
              <path d="M19520 3655 l0 -295 30 0 30 0 0 130 0 130 79 0 c69 0 84 -3 106 -23
23 -19 28 -34 36 -107 15 -132 14 -130 55 -130 30 0 35 3 30 17 -4 9 -11 58
-16 109 -9 105 -19 127 -62 154 l-30 18 31 16 c41 22 61 63 61 127 0 114 -56
149 -235 149 l-115 0 0 -295z m240 225 c53 -28 61 -127 13 -172 -21 -20 -37
-24 -109 -27 l-84 -3 0 104 c0 58 3 108 8 112 10 11 146 0 172 -14z"/>
              <path d="M14522 3190 c-129 -61 -240 -110 -247 -110 -7 0 -37 11 -67 24 -258
114 -576 108 -823 -17 -295 -148 -494 -436 -525 -756 -42 -447 225 -857 649
-996 l54 -18 -90 -46 c-137 -71 -204 -128 -274 -236 -50 -75 -73 -151 -72
-240 0 -218 150 -380 392 -422 173 -31 335 13 482 132 106 86 177 191 244 360
41 105 101 217 142 265 91 106 246 119 334 27 56 -58 59 -70 59 -249 l0 -168
-133 0 c-115 0 -136 -2 -149 -17 -8 -10 -50 -74 -93 -143 -43 -69 -85 -135
-93 -147 l-14 -23 296 0 296 0 0 348 0 347 -30 51 c-75 132 -229 189 -378 140
-63 -21 -145 -93 -198 -174 -20 -31 -57 -88 -82 -127 -132 -204 -251 -277
-447 -277 -117 0 -187 25 -243 86 -46 50 -57 90 -49 172 8 76 49 146 140 238
l77 79 71 -7 c144 -13 351 30 497 103 293 148 487 425 522 749 19 176 -15 363
-96 523 -43 86 -143 218 -205 272 -27 23 -49 45 -49 50 0 4 79 7 175 7 l175 0
0 155 c0 85 -3 155 -7 155 -5 -1 -113 -50 -241 -110z m-539 -98 c185 -92 319
-303 383 -602 25 -118 25 -391 0 -508 -64 -300 -196 -506 -380 -598 -233 -114
-476 6 -626 311 -227 461 -118 1124 224 1360 87 59 145 76 249 72 73 -4 98
-10 150 -35z"/>
              <path d="M18257 3169 c-189 -28 -370 -158 -496 -355 l-51 -79 0 208 c0 158 -3
209 -13 212 -7 3 -147 4 -311 3 l-299 -3 126 -84 127 -84 0 -766 0 -766 -105
-69 c-58 -38 -105 -70 -105 -72 0 -2 188 -4 418 -4 l417 0 -107 64 c-59 35
-113 66 -121 69 -11 4 -15 95 -20 546 -6 511 -6 544 12 601 46 147 167 267
336 332 83 32 229 33 296 2 52 -24 113 -83 139 -134 44 -86 44 -86 50 -800 l5
-685 303 -3 c166 -1 302 1 302 5 0 5 -52 37 -115 71 l-114 64 -3 631 -3 632
-31 76 c-111 275 -366 430 -637 388z"/>
              <path d="M5762 3160 c-371 -52 -670 -295 -788 -642 -55 -161 -59 -387 -9 -553
94 -320 352 -566 680 -652 144 -37 327 -37 470 1 426 112 715 483 715 917 0
422 -297 798 -716 904 -105 27 -259 38 -352 25z m283 -90 c111 -52 206 -155
278 -300 166 -335 166 -755 0 -1090 -47 -96 -69 -127 -132 -191 -54 -54 -96
-86 -146 -110 -64 -31 -77 -34 -165 -34 -91 0 -100 2 -172 38 -235 116 -398
459 -398 839 0 418 186 774 455 871 80 28 192 19 280 -23z"/>
              <path d="M15922 3160 c-599 -84 -969 -668 -781 -1234 100 -300 348 -529 664
-612 81 -21 124 -27 225 -27 175 -2 303 28 455 106 236 122 416 349 481 607
29 115 27 357 -4 465 -96 337 -351 585 -688 670 -105 27 -259 38 -352 25z
m291 -93 c125 -62 239 -198 308 -368 168 -414 89 -964 -177 -1222 -107 -102
-201 -142 -326 -135 -389 22 -648 648 -502 1217 35 135 118 301 194 384 110
120 215 170 347 164 70 -3 92 -9 156 -40z"/>
              <path d="M2760 3156 c0 -2 54 -39 120 -82 l120 -77 0 -581 c0 -655 1 -663 71
-805 117 -236 374 -367 624 -317 197 40 367 169 488 370 19 32 37 54 40 49 3
-4 5 -94 6 -198 0 -105 1 -196 1 -203 0 -10 63 -12 300 -11 165 0 300 4 300 7
0 4 -52 34 -115 67 -68 36 -115 66 -115 75 0 8 0 389 0 845 0 457 -4 836 -8
843 -7 9 -77 12 -316 10 l-308 -3 120 -79 120 -79 7 -191 c4 -105 5 -353 3
-551 l-3 -360 -32 -68 c-43 -91 -142 -194 -231 -240 -241 -127 -457 -70 -543
143 -11 29 -15 165 -19 735 l-5 700 -312 3 c-172 1 -313 0 -313 -2z"/>
              <path d="M6926 3151 c5 -4 62 -42 127 -84 l117 -77 0 -605 0 -606 27 -82 c51
-155 167 -290 308 -357 235 -112 501 -55 705 150 48 48 102 115 124 153 21 37
43 67 47 67 5 0 9 -92 9 -205 l0 -206 306 2 c168 0 303 4 299 7 -3 4 -57 34
-118 67 l-112 60 -3 858 -2 857 -317 0 -318 0 128 -85 127 -86 -2 -552 -3
-552 -28 -58 c-63 -127 -183 -233 -315 -279 -95 -33 -226 -32 -300 1 -65 29
-123 94 -154 170 l-23 56 -3 698 -3 697 -316 0 c-177 0 -313 -4 -307 -9z"/>
            </g>
          </svg>

        </Typography>

        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={illustration || '/assets/illustrations/ruoungon_login.png'}
          sx={{ maxWidth: 720 }}
        />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
