import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { Box, IconButton } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Iconify from '../iconify';
//
import MultiFilePreviewCustomize from './preview/MultiFilePreviewCustomize';

// ----------------------------------------------------------------------

UploadCustomizeForGood.propTypes = {
  sx: PropTypes.object,
  error: PropTypes.bool,
  files: PropTypes.array,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onDelete: PropTypes.func,
  onRemove: PropTypes.func,
  onUpload: PropTypes.func,
  thumbnail: PropTypes.bool,
  helperText: PropTypes.node,
  onRemoveAll: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default function UploadCustomizeForGood({
  disabled,
  multiple = false,
  error,
  helperText,
  //
  file,
  onDelete,
  srcImage = '',
  //
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  isEdit,
  sx,
  folder,
  ...other
}) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    disabled,
    ...other,
  });

  const hasFile = !!file && !multiple;

  const hasFiles = files && multiple && files.length > 0;

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      {hasFile && onDelete && (
        <>
          <IconButton
            size="small"
            onClick={onDelete}
            sx={{
              top: 16,
              right: 16,
              zIndex: 9,
              position: 'absolute',
              color: (theme) => alpha(theme.palette.common.white, 0.8),
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
              },
            }}
          >
            <Iconify icon="eva:close-fill" width={18} />
          </IconButton>
        </>
      )}

      {hasFiles && (
        <>
          <Box sx={{ my: 3 }}>
            <MultiFilePreviewCustomize
              files={files}
              thumbnail={thumbnail}
              onRemove={onRemove}
              isEdit
              srcImage={srcImage}
              folder={folder}
            />
          </Box>
        </>
      )}

      {helperText && helperText}
    </Box>
  );
}
