import PropTypes from 'prop-types';
import { useEffect } from 'react';
import ReactLightbox from 'react-image-lightbox';
// @mui
import { Typography, IconButton } from '@mui/material';
//
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ShareIcon from '@mui/icons-material/Share';
import StyledLightbox from './styles';

// ----------------------------------------------------------------------

Lightbox.propTypes = {
  open: PropTypes.bool,
  images: PropTypes.array,
  photoIndex: PropTypes.number,
  setPhotoIndex: PropTypes.func,
};

export default function Lightbox({ images, photoIndex, setPhotoIndex, open, onDownload, onShare, ...other }) {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [open]);

  const showIndex = (
    <Typography>
      <strong> {photoIndex + 1} </strong> {'/'} {images.length}
    </Typography>
  );
  const downloadFile = (
    <IconButton onClick={() => onDownload(photoIndex, 'download')}>
      <CloudDownloadIcon width={24} />
    </IconButton>
  );
  const shareFile = (
    <IconButton onClick={() => onShare(photoIndex, 'share')}>
      <ShareIcon width={24} />
    </IconButton>
  );
  // const downloadFile1 = <DownloadButton />;

  const toolbarButtons = [showIndex, downloadFile, shareFile];

  const customStyles = {
    overlay: {
      zIndex: 9999,
    },
  };

  return (
    <>
      <StyledLightbox />

      {open && (
        <>
          <ReactLightbox
            animationDuration={160}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
            toolbarButtons={toolbarButtons}
            reactModalStyle={customStyles}
            wrapperClassName="react-lightbox"
            {...other}
          />
        </>
      )}
    </>
  );
}
