import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { bgBlur } from '../../../utils/cssStyles';
import { IconButtonAnimate } from '../../animate';
import SvgColor from '../../svg-color';
import { useAuthContext } from '../../../auth/useAuthContext';
import { PAGES_ID } from '../../../utils/constant';

// ----------------------------------------------------------------------

export default function QuickActionDrawer() {
  const theme = useTheme();

  const navigate = useNavigate();

  const { user } = useAuthContext();

  const [canCreate, setCanCreate] = useState(false);

  const onRedirectPage = () => {
    if (canCreate) {
      navigate(PATH_DASHBOARD.salesReceipt.create);
    }
  };

  useEffect(() => {
    if (user) {
      const permissions = user?.roles?.map(({ permissions }) => permissions, []);

      const permissionAllowed = checkPermissions(permissions, PAGES_ID.create_order);

      setCanCreate(permissionAllowed.isCreate);
    }
  }, [user]);

  const checkPermissions = (permissions, pageId) => {
    const permissionAllowed = {
      isList: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
      isApprove: false,
      isDetail: false,
      isPayment: false,
      isExport: false,
      isPrint: false,
      isCreateAll: false,
      isInventory_hide: false,
    };

    permissions.forEach((rolePermissions) => {
      const rolePermissionPage = rolePermissions.filter((item) => item.screen_id === pageId);

      rolePermissionPage.forEach((permission) => {
        const permissionName = permission.name;
        permissionAllowed[
          `is${permissionName.split('-').pop().charAt(0).toUpperCase()}${permissionName.split('-').pop().slice(1)}`
        ] = true;
      });
    });
    return permissionAllowed;
  };

  return (
    <>
      {canCreate && (
        <Box
          className="hide-settings"
          sx={{
            p: 0.5,
            right: 24,
            bottom: 24,
            zIndex: 999,
            position: 'fixed',
            borderRadius: '50%',
            boxShadow: `-12px 12px 32px -4px ${alpha(
              theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.common.black,
              0.36
            )}`,
            ...bgBlur({ color: theme.palette.background.default }),
          }}
        >
          <Tooltip title="Thêm mới">
            <IconButtonAnimate color="primary" sx={{ p: 1.25 }} onClick={onRedirectPage}>
              <SvgColor src="/assets/icons/setting/ic_add.svg" />
            </IconButtonAnimate>
          </Tooltip>
        </Box>
      )}
    </>
  );
}
