import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  isLoading: false,
  costCategories: [],
  costGroups: [],
  costCategory: [],
  dataPaging: {},
};

const slice = createSlice({
  name: 'cost-category',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CATEGOGIRES
    getCostCategories(state, action) {
      state.isLoading = false;
      state.costCategories = action.payload;
    },
    // GET cost CATEGOGIRES SUCESS
    getCostCategorySuccess(state, action) {
      state.isLoading = false;
      state.costCategories = action.payload;
    },

    getCostGroups(state, action) {
      state.isLoading = false;
      state.costGroups = action.payload;
    },

    getDataPaging(state, action) {
      state.isLoading = false;
      state.dataPaging = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCostCategories(queryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('cost-categories', { params: queryParams });
      const { costCategories } = response?.data?.data;

      let newCostCategories = costCategories;
      if (newCostCategories?.per_page) {
        newCostCategories = newCostCategories.data;
      }

      dispatch(slice.actions.getCostCategorySuccess(newCostCategories));
      dispatch(slice.actions.getDataPaging(costCategories));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
