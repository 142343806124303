// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{
      width: 1,
      height: 1,
    }}
  />
);

const ICONS = {
  ric_internal_orgs: icon('ric_internal_orgs'),
  ric_employee: icon('ric_employee'),
  ric_party: icon('ric_party'),
  menuItem: icon('ic_menu_item'),
  ric_permission: icon('ric_permission'),
  ric_import_warehouse: icon('ric_import_warehouse'),
  ric_export_warehouse: icon('ric_export_warehouse'),
  ric_file_manager: icon('ric_file_manager'),
  ric_product: icon('ric_product'),
  ric_order_sample: icon('ric_order_sample'),
  ric_order: icon('ric_order'),
  ric_order_gift: icon('ric_order_gift'),
  ric_payment: icon('ric_payment'),
  ric_return_order: icon('ric_return_order'),
  ric_target: icon('ric_target'),
  ric_payment_v2: icon('ric_payment_v2'),
  ric_report_employee: icon('ric_report_employee'),
  ric_history: icon('ric_history'),
  ric_report_IE: icon('ric_report_IE'),
  ric_file_report: icon('ric_file_report'),
  ric_report_bar: icon('ric_report_bar'),
  ric_report_add: icon('ric_report_add'),
  ric_good_report: icon('ric_good_report'),
  ric_home_linear: icon('ric_home_linear'),

  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),

  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  phoneOutOne: icon('phone_call_1'),
  setting: icon('settings'),
  repair: icon('id_screwdriver-wrench'),
  contract: icon('ric_contract_line'),
  discount: icon('discount'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Hệ Thống',
    items: [
      {
        title: 'Tổ chức',
        path: PATH_DASHBOARD.organiztion.list,
        icon: ICONS.ric_internal_orgs,
        screen_id: 1,
      },
      {
        title: 'Nhân viên',
        path: PATH_DASHBOARD.employee.list,
        icon: ICONS.ric_employee,
        screen_id: 2,
      },
      {
        title: 'Khách hàng',
        path: PATH_DASHBOARD.parties.root,
        icon: ICONS.ric_party,
        children: [
          {
            title: 'Danh sách khách hàng',
            path: PATH_DASHBOARD.parties.list,
            screen_id: 4,
          },
          {
            title: 'Phân Loại Khách Hàng',
            path: PATH_DASHBOARD.parties.types,
            screen_id: 14,
          },
          {
            title: 'Nhóm Siêu Thị',
            path: PATH_DASHBOARD.parties.groupSupermarket,
            screen_id: 43,
          },
          {
            title: 'Hình Thức CSKH',
            path: PATH_DASHBOARD.crCategory.list,
            screen_id: 15,
          },
          {
            title: 'Lịch sử CSKH',
            path: PATH_DASHBOARD.customerCare.list,
            screen_id: 16,
          },
        ],
      },

      {
        title: 'Tin tức nội bộ',
        path: PATH_DASHBOARD.blog.list,
        icon: ICONS.blog,
        screen_id: 5,
      },
      {
        title: 'Phân quyền',
        path: PATH_DASHBOARD.userPermistion.root,
        icon: ICONS.ric_permission,
        children: [
          {
            title: 'Tài khoản',
            path: PATH_DASHBOARD.userPermistion.list,
            screen_id: 6,
          },
          {
            title: 'Vai trò',
            path: PATH_DASHBOARD.userPermistion.roles,
            screen_id: 7,
          },
          {
            title: 'Quyền',
            path: PATH_DASHBOARD.userPermistion.permistion,
            screen_id: 8,
          },
        ],
      },
    ],
  },
  {
    subheader: 'Quản lý kho',
    items: [
      {
        title: 'Nhập kho',
        path: PATH_DASHBOARD.importWarehouse.list,
        screen_id: 11,
        icon: ICONS.ric_import_warehouse,
      },
      {
        title: 'Xuất kho',
        path: PATH_DASHBOARD.exportWarehouse.list,
        screen_id: 12,
        icon: ICONS.ric_export_warehouse,
      },
      {
        title: 'Cập nhật tồn kho',
        path: PATH_DASHBOARD.inventoryManagement.list,
        screen_id: 41,
        icon: ICONS.ric_report_IE,
      },
    ],
  },

  {
    subheader: 'Bán hàng',
    items: [
      {
        title: 'Mục tiêu tháng',
        path: PATH_DASHBOARD.tagertMonth.list,
        icon: ICONS.ric_target,
        screen_id: 17,
      },
      {
        title: 'Quản lý đơn hàng',
        path: PATH_DASHBOARD.salesReceipt.root,
        icon: ICONS.ric_order,
        children: [
          {
            title: 'Danh sách đơn hàng',
            path: PATH_DASHBOARD.salesReceipt.list,
            screen_id: 20,
          },
          {
            title: 'Tạo mới đơn hàng',
            path: PATH_DASHBOARD.salesReceipt.create,
            screen_id: 19,
          },
          {
            title: 'Tạo mới đơn hàng siêu thị',
            path: PATH_DASHBOARD.salesReceipt.createSupermarket,
            screen_id: 38,
          },
          {
            title: 'Đơn hàng tặng',
            path: PATH_DASHBOARD.orderGift.list,
            // icon: ICONS.ric_order_gift,
            screen_id: 21,
          },
          {
            title: 'Báo giá',
            path: PATH_DASHBOARD.orderSample.list,
            // icon: ICONS.ric_order_sample,
            screen_id: 18,
          },
          {
            title: 'Trả hàng',
            path: PATH_DASHBOARD.refundOrder.create,
            // icon: ICONS.ric_return_order,
            screen_id: 22,
          },
        ],
      },
      {
        title: 'Thanh toán',
        path: PATH_DASHBOARD.paymentMultipleOrder.root,
        icon: ICONS.ric_payment_v2,
        children: [
          {
            title: 'Quản lý thanh toán',
            path: PATH_DASHBOARD.paymentMultipleOrder.list,
            screen_id: 23,
          },
          {
            title: 'Tạo mới thanh toán',
            path: PATH_DASHBOARD.paymentMultipleOrder.create,
            screen_id: 23,
          },
          {
            title: 'Danh mục tài khoản',
            path: PATH_DASHBOARD.paymentMultipleOrder.paymentAccount,
            screen_id: 44,
          },
        ],
      },
      {
        title: 'Hợp đồng',
        path: PATH_DASHBOARD.conclusionContract.list,
        icon: ICONS.contract,
        screen_id: 25,
      },
      {
        title: 'Sản phẩm',
        path: PATH_DASHBOARD.good.root,
        icon: ICONS.ric_product,
        children: [
          {
            title: 'Sản phẩm',
            path: PATH_DASHBOARD.good.list,
            screen_id: 10,
          },
          {
            title: 'Sản phẩm siêu thị',
            path: PATH_DASHBOARD.good.superMarket,
            screen_id: 35,
          },
          {
            title: 'Loại sản phẩm',
            path: PATH_DASHBOARD.good.category,
            screen_id: 9,
          },
        ],
      },
      {
        title: 'Quản lý hồ sơ',
        path: PATH_DASHBOARD.fileManager.list,
        icon: ICONS.ric_file_manager,
        screen_id: 26,
      },
      // {
      //   title: 'Quản lý chi phí',
      //   path: PATH_DASHBOARD.costManagement.list,
      //   icon: ICONS.invoice,
      //   screen_id: 37,
      // },
      {
        title: 'Quản lý chi phí',
        path: PATH_DASHBOARD.costManagement.root,
        icon: ICONS.invoice,
        children: [
          {
            title: 'Danh sách chi phí',
            path: PATH_DASHBOARD.costManagement.list,
            screen_id: 37,
          },
          {
            title: 'Danh mục chi phí',
            path: PATH_DASHBOARD.costManagement.category,
            screen_id: 42,
          },
        ],
      },
      {
        title: 'Hoa hồng',
        path: PATH_DASHBOARD.commission.root,
        icon: ICONS.discount,
        children: [
          {
            title: 'Chi trả hoa hồng',
            path: PATH_DASHBOARD.commission.pay,
            screen_id: 34,
          },
          {
            title: 'Danh mục hoa hồng',
            path: PATH_DASHBOARD.commission.list,
            screen_id: 33,
          },
        ],
      },
    ],
  },

  {
    subheader: 'Báo cáo',
    items: [
      {
        title: 'Công việc hàng ngày',
        path: PATH_DASHBOARD.workDaily.list,
        screen_id: 27,
        icon: ICONS.analytics,
      },
      {
        title: 'Xuất nhập tồn',
        path: PATH_DASHBOARD.importInventory.list,
        icon: ICONS.ric_home_linear,
        screen_id: 28,
      },
      {
        title: 'Tồn kho',
        path: PATH_DASHBOARD.inventory.list,
        icon: ICONS.ric_report_IE,
        screen_id: 40,
      },
      {
        title: 'Công nợ',
        path: PATH_DASHBOARD.debts.list,
        icon: ICONS.ric_file_report,
        screen_id: 29,
      },
      {
        title: 'Doanh thu theo khách hàng',
        path: PATH_DASHBOARD.revenueCustomer.list,
        icon: ICONS.ric_report_bar,
        screen_id: 30,
      },
      {
        title: 'Doanh thu theo nhân viên',
        path: PATH_DASHBOARD.revenueEmployee.list,
        icon: ICONS.ric_report_add,
        screen_id: 31,
      },
      {
        title: 'Doanh số theo sản phẩm',
        path: PATH_DASHBOARD.goodReport.list,
        icon: ICONS.ric_good_report,
        screen_id: 32,
      },
    ],
  },
];

export default navConfig;
