import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  supermarkets: [],
  dataPaging: {},
};

const slice = createSlice({
  name: 'supermarkes',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSupermarkets(state, action) {
      state.isLoading = false;
      state.supermarkets = action.payload;
    },

    getDataPaging(state, action) {
      state.isLoading = false;
      state.dataPaging = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getSupermarkets(queryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('supermarkets', {
        params: queryParams,
      });
      const supermarkets = response?.data?.data?.supermarkets || [];

      let newSupermarket = supermarkets;
      if (newSupermarket?.per_page) {
        newSupermarket = newSupermarket.data;
      }

      const newData = newSupermarket.map((item, key) => {
        return {
          ...item,
          party_name: item?.party?.name,
          goods: item?.good_supermarket_goods,
        };
      });

      dispatch(slice.actions.getSupermarkets(newData));
      dispatch(slice.actions.getDataPaging(supermarkets));
    } catch (error) {
      console.error('error ', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
