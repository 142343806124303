import { useMemo } from 'react';
import useSWR from 'swr';
// utils
import { fetcher } from '../utils/axios';

// ----------------------------------------------------------------------

export function useNotifications(params) {
  const URL = ['notifications', { params }];

  const { data, error, isLoading, mutate } = useSWR(URL, fetcher, {
    revalidateOnFocus: true,
    refreshInterval: 15000,
  });

  return useMemo(
    () => ({
      notifications: data?.data?.notifications?.data || [],
      countNotiNotViewed: data?.data?.countNotiNotViewed,
      totalNoti: data?.data?.totalNoti,
      isLoading,
      mutate,
    }),
    [data, error, isLoading, mutate]
  );
}
