import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  debts: [],
  renvenueCustomer: [],
  renvenueEmployee: [],
  inventories: [],
  dataPaging: {},
  goodsInventories: [],
  totalDebtLimit: 0,
  totalRemainPrice: 0,
  totalDoubtfulDebt: 0,
  totalOverdueDebt: 0,
  totalUndueDebt: 0,
  totalOldDebt: 0,
};

const slice = createSlice({
  name: 'screen-report',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Debts
    getDebtsSuccess(state, action) {
      state.isLoading = false;
      state.debts = action.payload;
    },

    // GET Renvenue Customer
    getRenvenueCustomerSuccess(state, action) {
      state.isLoading = false;
      state.renvenueCustomer = action.payload;
    },

    getRenvenueEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.renvenueEmployee = action.payload;
    },

    // GET INVENTORIES
    getInventories(state, action) {
      state.isLoading = false;
      state.inventories = action.payload;
    },

    getDataPaging(state, action) {
      state.isLoading = false;
      state.dataPaging = action.payload;
    },

    getGoodsInventories(state, action) {
      state.isLoading = false;
      state.goodsInventories = action.payload;
    },

    getTotalDebtLimit(state, action) {
      state.isLoading = false;
      state.totalDebtLimit = action.payload;
    },

    getTotalRemainPrice(state, action) {
      state.isLoading = false;
      state.totalRemainPrice = action.payload;
    },

    getTotalDoubtfulDebt(state, action) {
      state.isLoading = false;
      state.totalDoubtfulDebt = action.payload;
    },

    getTotalOverdueDebt(state, action) {
      state.isLoading = false;
      state.totalOverdueDebt = action.payload;
    },

    getTotalUndueDebt(state, action) {
      state.isLoading = false;
      state.totalUndueDebt = action.payload;
    },
    getTotalOldDebt(state, action) {
      state.isLoading = false;
      state.totalOldDebt = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function getDebts(queryData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('report-debts', { params: queryData });
      const dataDebts = response?.data?.data?.debts || [];
      let newDataDebts = dataDebts?.data;
      if (dataDebts?.data?.per_page) {
        newDataDebts = newDataDebts.data;
      }

      dispatch(slice.actions.getDebtsSuccess(newDataDebts));
      dispatch(slice.actions.getDataPaging(dataDebts?.data));
      dispatch(slice.actions.getTotalDebtLimit(dataDebts?.total_debt_limit));
      dispatch(slice.actions.getTotalRemainPrice(dataDebts?.total_remain_price));
      dispatch(slice.actions.getTotalDoubtfulDebt(dataDebts?.total_doubtful_debt));
      dispatch(slice.actions.getTotalOverdueDebt(dataDebts?.total_overdue_debt));
      dispatch(slice.actions.getTotalUndueDebt(dataDebts?.total_undue_debt));
      dispatch(slice.actions.getTotalOldDebt(dataDebts?.total_old_debt));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRenvenueCustomer(queryData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('get-party-reports', { params: queryData });
      const reports = response?.data?.data?.reports || [];
      let newReport = reports;
      if (newReport?.per_page) {
        newReport = newReport.data;
      }

      dispatch(slice.actions.getRenvenueCustomerSuccess(newReport));
      dispatch(slice.actions.getDataPaging(reports));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRenvenueEmployee(queryData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('get-employee-reports', { params: queryData });
      const reports = response?.data?.data?.reports || [];
      let newReport = reports;
      if (newReport?.per_page) {
        newReport = newReport.data;
      }

      dispatch(slice.actions.getRenvenueEmployeeSuccess(newReport));
      dispatch(slice.actions.getDataPaging(reports));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInventories(queryData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('report-inventory', { params: queryData });
      const dataInventories = response?.data?.data?.inventories || [];
      let newInventories = dataInventories;
      if (dataInventories?.per_page) {
        newInventories = newInventories.data;
      }

      dispatch(slice.actions.getInventories(newInventories));
      dispatch(slice.actions.getDataPaging(dataInventories));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGoodsInventories(queryData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('get-goods-inventory', { params: queryData });
      const dataInventories = response?.data?.data || [];
      let newInventories = dataInventories;
      if (dataInventories?.per_page) {
        newInventories = newInventories.data;
      }

      const newData = newInventories.map((item, key) => ({
        ...item,
        increment: key + (newInventories?.from || 1),
        unit_of_measure_name: item?.unit_of_measure?.name,
        total_inventory: item?.daily_product_balance.reduce((total, row) => total + Number(row.opening_quantity), 0),
      }));

      dispatch(slice.actions.getGoodsInventories(newData));
      dispatch(slice.actions.getDataPaging(dataInventories));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
