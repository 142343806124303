import { createSlice } from '@reduxjs/toolkit';
import { currencyFormatter } from '../../utils/formatNumber';

// utils
import axios from '../../utils/axios';
import { PARTY_TYPE } from '../../utils/constant';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  costs: [],
  totalPrice: 0,
  costStatuses: [],
  costCount: [],
  dataPaging: {},
  partiesByEmployee: [],
};

const slice = createSlice({
  name: 'costs',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PARTIES
    getCosts(state, action) {
      state.isLoading = false;
      state.costs = action.payload;
    },

    getCostStatuses(state, action) {
      state.isLoading = false;
      state.costStatuses = action.payload;
    },

    getCostCount(state, action) {
      state.isLoading = false;
      state.costCount = action.payload;
    },

    getTotalPrice(state, action) {
      state.isLoading = false;
      state.totalPrice = action.payload;
    },

    getDataPaging(state, action) {
      state.isLoading = false;
      state.dataPaging = action.payload;
    },

    // GET PARTIES BY EMPLOYEE
    getListPartiesByEmployee(state, action) {
      state.isLoading = false;
      state.partiesByEmployee = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
const STATUS_OPTION = [
  { label: 'Thấp', value: 1 },
  { label: 'Trung bình', value: 2 },
  { label: 'Cao', value: 3 },
];

function getLabelFromValue(value) {
  const option = STATUS_OPTION.find((opt) => opt.value === value);
  return option ? option.label : null;
}

export function getCosts(queryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get('costs', {
        params: queryParams,
      });
      const costs = response?.data?.data?.costs?.query || [];
      const totalPrice = response?.data?.data?.costs?.totalPrice || [];
      const costCount = response?.data?.data?.costs?.tabCount || [];
      const costStatuses = response?.data?.data?.costStatuses || [];

      let newCosts = costs;
      if (costs?.per_page) {
        newCosts = newCosts.data;
      }

      const newData = newCosts.map((item, key) => {
        return {
          ...item,
          warehouse_name: item?.user?.internal_org?.name,
          user_name: item?.user?.name,
          priority_name: getLabelFromValue(item?.priority),
          status_name: item?.cost_status?.name,
          approved_name: item?.approved_user?.name,
        };
      });

      dispatch(slice.actions.getCosts(newData));
      dispatch(slice.actions.getCostStatuses(costStatuses));
      dispatch(slice.actions.getCostCount(costCount));
      dispatch(slice.actions.getTotalPrice(currencyFormatter(totalPrice)));
      dispatch(slice.actions.getDataPaging(costs));
    } catch (error) {
      console.error('error ', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
