import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  isLoading: false,
  areas: [],
  dataPaging: {},
};

const slice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PAGINATION
    getDataPaging(state, action) {
      state.isLoading = false;
      state.dataPaging = action.payload;
    },

    // GET BLOG
    getAreas(state, action) {
      state.isLoading = false;
      state.areas = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAreas(queryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('areas', { params: queryParams });
      const areas = response?.data?.data || [];

      let newAreas = areas;

      if (newAreas?.per_page) {
        newAreas = newAreas.data;
      }

      dispatch(slice.actions.getDataPaging(areas));
      dispatch(slice.actions.getAreas(newAreas));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
